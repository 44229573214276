import React, { useEffect, useState } from 'react';

import { request } from 'utils/request';
import { mapLegacyArtworkToArtwork } from 'features/artworks/api';
import * as Types from 'types';

import { FAQs, PdlVideo, PdlHeroText, PdlCarousel, PdlCta, PdlLogoBlock } from './components';

import FadeAndSlide from 'components/animations/FadeAndSlide';
import TextBlock from 'components/TextBlock';
import ImgTextSplit from 'components/ImgTextSplit';
import { PDL_BLOCKS, HERO_TEXT, CTA_CONTENT } from './pdlContent';

const artworkUrl = '/api/internal/artworks';
const artistUrl = '/api/internal/users';
const artworkParams = new URLSearchParams([
  ['serializer', 'legacy'],
  ['filter', 'slate_name'],
  ['slate_name', 'carousel-artworks'],
]);
const artistParams = new URLSearchParams([
  ['serializer', 'legacy'],
  ['filter', 'slate_name'],
  ['slate_name', 'carousel-artists'],
]);

export const PublicDisplaysPage = () => {
  const [artworks, setArtworks] = useState([] as Types.Artwork[]);
  const [artists, setArtists] = useState([] as Types.Artist[]);

  useEffect(() => {
    request.get(`${artworkUrl}?${artworkParams.toString()}`).then(({ data }) => {
      const artworksArray: Types.Artwork[] = data.map(mapLegacyArtworkToArtwork);
      setArtworks(artworksArray);
    });

    request.get(`${artistUrl}?${artistParams.toString()}`).then(({ data }) => {
      setArtists(data);
    });
  }, []);

  return (
    <main className='public-displays'>
      <div className='public-displays__content--hero'>
        <ImgTextSplit imgSide='right' className='public-displays__content'>
          <div className='pdl-hero__video public-displays__lazy-load'>
            <FadeAndSlide>
              <PdlVideo fileName='pdl-hero-wisse.mp4' poster='pdl-hero-poster.jpg' />
            </FadeAndSlide>
          </div>
          <PdlHeroText
            text='Digital Art For Your'
            swappingText={HERO_TEXT}
            btnText='Book a call'
            btnLink='https://calendly.com/seditionart/30min'
          />
        </ImgTextSplit>
      </div>
      <div className='public-displays__gradient--down'>
        <PdlCarousel
          id='carousel-1'
          smallHeading='Vast collection of digital art'
          heading='Hundreds of artists and thousands of artworks to choose from'
          textWidth='narrow'
          initialArtworks={artworks}
          staggered
        />
      </div>
      <div className='public-displays--grey'>
        {PDL_BLOCKS.map(({ fileName, smallHeading, heading, text }, idx) => (
          <ImgTextSplit key={idx} imgSide={idx % 2 === 0 ? 'left' : 'right'}>
            <FadeAndSlide>
              <PdlVideo fileName={fileName} />
            </FadeAndSlide>
            <TextBlock size='sm' heading={heading} smallHeading={smallHeading} text={text} />
          </ImgTextSplit>
        ))}
      </div>

      <div className='mb-3'>
        <div className="video-section">
          <video autoPlay loop muted>
            <source src="https://sedition-website.s3.us-east-1.amazonaws.com/art-for-business/bring-art-to-business-bg.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          <div className="content-wrapper">
            <h1 className="headline">Bring Art To Your Business</h1>
            <p className="description">
              Tell us about your venue and your vision,
              <br />
              and our world-class curation team will curate a selection of artworks catered for your business.
            </p>
            <a className="btn btn--success" href="https://calendly.com/seditionart/30min" target="_blank" rel="noopener noreferrer">Book a call</a>
            <a className="btn btn--success" href="mailto:business@seditionart.com" target="_blank" rel="noopener noreferrer">Send us an email</a>
          </div>
        </div>
      </div>

      <PdlCarousel
        id='carousel-2'
        smallHeading="We're part of the art world"
        heading="Trusted relationships with many of the world's leading artists and art institutions."
        textWidth='regular'
        initialArtists={artists}
      />
      <PdlLogoBlock />
      <div className='public-displays__content'>
        <FAQs />
      </div>
    </main>
  );
};
